import './SideBar.css'
import {Link, useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {APP_URL_PREFIX} from "../../utilities//Constants";
import CompanyTeamContext from "../../CompanyTeamContext";
import FlexBox from "../utilities/FlexBox";
import {ChevronBarLeft, ChevronBarRight, X} from "react-bootstrap-icons";
import {TEST_BUILDER_PRIVILEGE} from "../../utilities/Constants";

import ModalBackground from "../utilities/modal/ModalBackground";

import * as SVGS from '../../assets/side-bar/SVGS'
import React from "react";
import LogoImg from '../../assets/newLogo.svg'



const SideBar = ({active}) => {
    let {name} = useContext(CompanyTeamContext) || {}
    const [collapse, setCollapse] = useState(false)
    let collapseClass = collapse ? "collapse-bar" : "";
    const [privileges, setPrivileges] = useState([])
    const [plan, setPlan] = useState("")
    const company = useContext(CompanyTeamContext)
    const [showSupport, setShowSupport] = useState(false)

    useEffect(() => {
        if (company && company.privileges) {
            setPrivileges(company.privileges || []);
        }
        if (company && `company.selectedLitePlan`) {
            setPlan(company.selectedLitePlan)
        }
    }, [company])

    return (<div className={"side-bar " + collapseClass} style={{position: "relative", zIndex: 1}}>
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%"
        }}>
            <div>
        <span hidden={collapse}>
            <Logo name={name} src={LogoImg}/>
        </span>
                <CollapseBar collapse={collapse} onClick={e => setCollapse(!collapse)}/>
                <br/>
                <SideBarButtons setShowSupport={setShowSupport} company={company} hidden={collapse} privileges={privileges} plan={plan}/>
            </div>
        </div>
        {showSupport && <SupportModal show={showSupport} setShow={setShowSupport} />}
    </div>)
}

const SupportModal = ({setShow}) => {
    return (<ModalBackground style={{position:"fixed"}}>
        <FlexBox justify={"center"} align={"center"} direction={"column"} className={"hard-shadow"} style={{position:"relative",width:"60%", maxWidth:"400px", height:"70%", maxHeight:"170px", background:"white"}}>
          <X size={"25px"} style={{position:"absolute",right:0,top:0, cursor:"pointer"}} onClick={e=>setShow(false)}/>
            <p style={{fontWeight:"bold", fontSize:"25px"}}>Need Help?</p>
            <p style={{fontSize:"18px"}}>Contact us at <a href={"mailto:info@lugo-test.com"}>info@lugo-test.com</a></p>
        </FlexBox>
    </ModalBackground>)
}

function CollapseBar(props) {
    return <div style={{textAlign: "right", position: "absolute", top: 15, right: 5}}>
        {props.collapse ? <ChevronBarRight size={"20px"} onClick={props.onClick} style={{cursor: "pointer"}}/> :
            <ChevronBarLeft size={"20px"} onClick={props.onClick} style={{cursor: "pointer"}}/>}
    </div>;
}

function SideBarButtons(props) {
 
    return <>
        <Page hidden={props.hidden} style={{marginBottom: "15px"}} icon={SVGS.SVGDashboard} name={"Dashboard"}
              target={APP_URL_PREFIX + "/dashboard"}/>

        <Category hidden={props.hidden} name={"Test"}>
            <Page disabled={!props.privileges.includes(TEST_BUILDER_PRIVILEGE)} icon={SVGS.SVGNewTest} name={"New Test"}
                  target={APP_URL_PREFIX + "/test/new"}/>

            <Page disabled={!props.privileges.includes(TEST_BUILDER_PRIVILEGE)} icon={SVGS.SVGNewLiveTest} name={"New Live Test"}
                  target={APP_URL_PREFIX + "/test/live/new"}/>

            <Page disabled={!props.plan} icon={SVGS.SVGTestsStatus}
                  name={"Tests Status"} target={APP_URL_PREFIX + "/quiz/list"}/>

            <Page icon={SVGS.SVGTestsList} name={"Tests Library"}
                  target={APP_URL_PREFIX + "/test/list"}/>

            <Page disabled={!props.privileges.includes(TEST_BUILDER_PRIVILEGE)} icon={SVGS.SVGPrivateQuestions} name={"Private Questions"}
                  target={APP_URL_PREFIX + "/test/questions/builder"}/>

            <Page disabled={!props.plan} icon={SVGS.SVGTestWizard} name={"New Test Wizard"}
                  target={APP_URL_PREFIX + "/test/new/wizard"}/>


        </Category>

        <Category hidden={props.hidden} name={"Candidate"}>
            <Page disabled={!props.plan} icon={SVGS.SVGNewCandidate}
                  name={"New Candidate"} target={APP_URL_PREFIX + "/candidate/new"}/>
            <Page disabled={!props.plan} icon={SVGS.SVGCandidatesList}
                  name={"Candidates List"} target={APP_URL_PREFIX + "/candidate/list"}/>
        </Category>


        <Category hidden={props.hidden || (props.company?.isBusinessLiteCompany && props?.company?.loggedLiteUser?.role != 1)} name={"Billing"}>
            <Page icon={SVGS.SVGBilling} name={"Plans & Billing"} target={APP_URL_PREFIX + "/billing"}/>
            <Page icon={SVGS.SVGPricing} name={"Pricing"} target={APP_URL_PREFIX + "/pricing"}/>
            <Page icon={SVGS.SVGInvoices} name={"Invoices"} target={APP_URL_PREFIX + "/invoices"}/>
            <Page disabled={!props.plan} icon={SVGS.SVGTestsStatus}
            name={"Tests Reports"} target={APP_URL_PREFIX + "/quiz/report"}/>
		
        </Category>

        <Category hidden={props.hidden || !(props?.company?.loggedLiteUser) || props?.company?.loggedLiteUser?.role != 1 || !(props.company?.isBusinessLiteCompany) } name={"Company"}>
            <Page icon={SVGS.SVGCandidatesList} name={"Users Management"} target={APP_URL_PREFIX + "/user/manage"}/>
        </Category>

        <Category hidden={props.hidden || (props.company?.isBusinessLiteCompany && props?.company?.loggedLiteUser?.role != 1)} name={"Preferences"}>
            <Page  icon={SVGS.SVGSettings} name={"Mail Invitation"} target={APP_URL_PREFIX + "/preferences"}/>
            <Page  icon={SVGS.SVGSettings} name={"User Agreement"} target={APP_URL_PREFIX + "/preferences/agreement"}/>
        </Category>


        <div hidden={props.hidden} className={"page"}>
            {React.createElement(SVGS.SVGSupport, {})}
            <a href={"https://support.lugo-test.com/support/solutions"} target="_blank" rel="noopener noreferrer" className={"page-title"}>{"Support"}</a>
        </div>
    </>;
}


const Category = ({name, children, hidden}) => {
    return (<div hidden={hidden} className={"category"}>
        <p style={{marginBottom: "0px"}} className={"category-title"}>{name}</p>
        {children}
    </div>)
}

const Page = ({icon, name, target, disabled, hidden, style = {}}) => {
    const loc = useLocation()
    const [active, setActive] = useState(false);
    useEffect(() => {
        setActive(loc.pathname == target);
    }, [loc])
    if (hidden) return "";
    return (
        <div style={{...style, cursor: disabled ? "not-allowed" : ""}} title={disabled ? "Select Plan to Start." : ""}
             className={"page " + (active ? "active" : "") + " " + (disabled ? "disabled" : "")}>
            {/*<img className={(disabled ? "disabled-img" : "") + (active ? " active-img" : "")} src={icon} alt={name}/>*/}
            {React.createElement(icon, {color:(disabled && !active ? ("#ebebeb") : (active ? "#764BF2" : "#7b7880"))})}
            {disabled ? <Link disabled={disabled} to={"#"} onClick={e => e.preventDefault()}
                              className={"page-title page-title-disabled"}>{name}</Link> :
                <Link disabled={disabled} to={target} className={"page-title"}>{name}</Link>}

        </div>)
}

const Logo = ({src, name}) => {
    return (<div style={{marginBottom: "7px",
        display: "flex",
        justifyContent: "start",
        fontSize: "20px",
        alignItems: "flex-end"}}
                 className={"page no-hover-background"}>
        <img src={src} alt={name} style={{maxWidth: "90%"}}/>Lugo-Test
    </div>)
}


export default SideBar